<template>
  <div class="prodetail">
    <van-nav-bar fixed title="商品详情页" left-arrow @click-left="$router.go(-1)" />

    <van-swipe :autoplay="3000" @change="onChange">
      <van-swipe-item v-for="(image, index) in images" :key="index">
        <img :src="image" />
      </van-swipe-item>

      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }} / {{ images.length }}</div>
      </template>
    </van-swipe>

    <!-- 商品说明 -->
    <div class="info">
      <div class="title">
        <div class="price">
          <span class="now">￥4599</span>
          <span class="oldprice">￥4999.00</span>
        </div>
        <div class="sellcount">已售999+件</div>
      </div>
      <div class="msg text-ellipsis-2">
        小米手机 Xiaomi14pro  12GB+256GB 徕卡Summilux可变光圈镜头 第三代骁龙®8移动平台 白色
        5G手机
      </div>

      <div class="service">
        <div class="left-words">
          <span><van-icon name="passed" />七天无理由退货</span>
          <span><van-icon name="passed" />当天发货</span>
          <span><van-icon name="passed" />京宝自营</span>
        </div>
        <div class="right-icon">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>

    <!-- 商品评价 -->
    <div class="comment">
      <div class="comment-title">
        <div class="left">商品评价 (999+条)</div>
        <div class="right">查看更多<van-icon name="arrow" /> </div>
      </div>
      <div class="comment-list">
        <div class="comment-item" v-for="item in 3" :key="item">
          <div class="top">
            <img src="@/assets/touxiang.jpg" alt="">
            <div class="name">爱情好像流沙</div>
            <van-rate :size="16" :value="5" color="#ffd21e" void-icon="star" void-color="#eee"/>
          </div>
          <div class="content">
            第三代骁龙®8非常强悍 澎湃OSyyds
          </div>
          <div class="time">
            2024-09-13 17:27:35
          </div>
        </div>
      </div>
    </div>

    <!-- 商品描述 -->
    <div class="desc">
      <img src="https://uimgproxy.suning.cn/uimg1/sop/commodity/kHgx21fZMWwqirkMhawkAw.jpg" alt="">
      <img src="https://m.360buyimg.com/babel/jfs/t1/234834/32/26948/95630/66d1c6eaF49e84a67/8be8306da7b19bae.jpg.webp" alt="">
      <img src="https://m11.360buyimg.com/babel/jfs/t1/23714/29/22336/59257/66aa0497Fb427afce/333c8a2f13a7274c.jpg!q70.dpg.webp" alt="">
      <img src="@/assets/deatil.jpg" alt="">
    </div>

    <!-- 底部 -->
    <div class="footer">
      <div @click="$router.push('/')" class="icon-home">
        <van-icon name="wap-home-o" />
        <span>首页</span>
      </div>
      <div @click="$router.push('/cart')" class="icon-cart">
        <span v-if="cartTotal > 0" class="num"> cartTotal </span>
        <van-icon name="shopping-cart-o" />
        <span>购物车</span>
      </div>
      <div @click="addFn" class="btn-add">加入购物车</div>
      <div @click="buyFn" class="btn-buy">立刻购买</div>
    </div>

     <!-- 加入购物车的弹层 -->
     <van-action-sheet v-model="showPannel" :title="mode === 'cart' ? '加入购物车' : '立刻购买'">
  <div class="product">
    <div class="product-title">
      <div class="left">
        <img src="@/assets/product.jpg" alt="">
      </div>
      <div class="right">
        <div class="price">
          <span>¥</span>
          <span class="nowprice">4299</span>
        </div>
        <div class="count">
          <span>库存</span>
          <span>999+</span>
        </div>
      </div>
    </div>
    <div class="num-box">
      <span>数量</span>
      <!-- v-model本质上：vavle 和 @input 的简写 -->
      <CountBox v-model="addCount"></CountBox>
    </div>
    <div class="showbtn" v-if="true">
      <div class="btn" v-if="true" @click="addCart">加入购物车</div>
      <div class="btn now" v-else @click="goBuyNow">立即购买</div>
    </div>
    <div class="btn-none" v-else>该商品已下架</div>
  </div>
</van-action-sheet>
  </div>

</template>

<script>
import CountBox from '@/components/CountBox.vue'
import { addCart } from '@/api/cart'
export default {
  name: 'ProDetail',
  components: {
    CountBox
  },
  data () {
    return {
      images: [
        'https://img10.360buyimg.com/n1/s450x450_jfs/t1/233606/2/25253/36160/66d94aaaFd4cab16f/0ad9a310d369157f.jpg.avif',
        'https://img10.360buyimg.com/n1/s450x450_jfs/t1/166756/37/46217/33137/6662d6bdF70ed4dcf/1754063b648e79ee.jpg.avif',
        'https://img10.360buyimg.com/n1/s450x450_jfs/t1/236915/1/804/44790/653a2befF2775d7f0/ab127bdd1b690ba1.jpg.avif'
      ],
      current: 0,
      showPannel: false,
      mode: 'cart',
      addCount: 1
    }
  },
  methods: {
    onChange (index) {
      this.current = index
    },
    addFn () {
      this.mode = 'cart'
      this.showPannel = true
    },
    buyFn () {
      this.mode = 'buyFn'
      this.showPannel = true
    },
    async addCart () {
      // 判断 token 是否存在
      // 1.如果不存在，则跳转到登录页面
      // 2.如果存在，则将数据添加到购物车
      if (!this.$store.getters.token) {
        // 弹出确认登录框
        this.$dialog.confirm({
          title: '温馨提示',
          message: '该操作需要999VIP权限',
          confirmButtonText: '去登录',
          cancelButtonText: '先逛逛'
        })
          .then(() => {
            // 跳转登录 登录后再回弹当前页面 跳转前去携带参数：当前路径的地址
            this.$router.replace({
              path: '/login',
              query: {
                backUrl: this.$route.fullPath
              }
            })
          })
          .catch(() => {})
        return
      }

      const data = await addCart(this.goodsId, this.addCount, this.detail.skuList[0].goods_sku_id)
      this.cartTotal = data.cartTotal
      this.$toast('加入购物车成功')
      this.showPannel = false
    }

  }

}

</script>

<style lang="less" scoped>
.prodetail {
  padding-top: 46px;
  ::v-deep .van-icon-arrow-left {
    color: #333;
  }
  img {
    display: block;
    width: 100%;
  }
  .custom-indicator {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 5px 10px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }
  .desc {
    width: 100%;
    overflow: scroll;
    ::v-deep img {
      display: block;
      width: 100%!important;
    }
  }
  .info {
    padding: 10px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    .now {
      color: #fa2209;
      font-size: 20px;
    }
    .oldprice {
      color: #959595;
      font-size: 16px;
      text-decoration: line-through;
      margin-left: 5px;
    }
    .sellcount {
      color: #959595;
      font-size: 16px;
      position: relative;
      top: 4px;
    }
  }
  .msg {
    font-size: 16px;
    line-height: 24px;
    margin-top: 5px;
  }
  .service {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    margin-top: 10px;
    font-size: 16px;
    background-color: #fafafa;
    .left-words {
      span {
        margin-right: 10px;
      }
      .van-icon {
        margin-right: 4px;
        color: #fa2209;
      }
    }
  }

  .comment {
    padding: 10px;
  }
  .comment-title {
    display: flex;
    justify-content: space-between;
    .right {
      color: #959595;
    }
  }

  .comment-item {
    font-size: 16px;
    line-height: 30px;
    .top {
      height: 30px;
      display: flex;
      align-items: center;
      margin-top: 20px;
      img {
        width: 20px;
        height: 20px;
      }
      .name {
        margin: 0 10px;
      }
    }
    .time {
      color: #999;
    }
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 55px;
    background-color: #fff;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .icon-home, .icon-cart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      .van-icon {
        font-size: 24px;
      }
    }
    .btn-add,
    .btn-buy {
      height: 36px;
      line-height: 36px;
      width: 120px;
      border-radius: 18px;
      background-color: #ffa900;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }
    .btn-buy {
      background-color: #fe5630;
    }
  }
}

.tips {
  padding: 10px;
}

// 弹层的样式
.product {
  .product-title {
    display: flex;
    .left {
      img {
        width: 90px;
        height: 90px;
      }
      margin: 10px;
    }
    .right {
      flex: 1;
      padding: 10px;
      .price {
        font-size: 14px;
        color: #fe560a;
        .nowprice {
          font-size: 24px;
          margin: 0 5px;
        }
      }
    }
  }

  .num-box {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
  }

  .btn, .btn-none {
    height: 40px;
    line-height: 40px;
    margin: 20px;
    border-radius: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 148, 2);
  }
  .btn.now {
    background-color: #fe5630;
  }
  .btn-none {
    background-color: #cccccc;
  }
}

.footer .icon-cart {
  position: relative;
  padding: 0 6px;
  .num {
    z-index: 999;
    position: absolute;
    top: -2px;
    right: 0;
    min-width: 16px;
    padding: 0 4px;
    color: #fff;
    text-align: center;
    background-color: #ee0a24;
    border-radius: 50%;
  }
}
</style>
