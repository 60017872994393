<template>
    <div class="order-list-item">
      <div class="tit">
        <div class="time">2024-0915-01 18:02:13</div>
        <div class="status">
          <span>待支付</span>
        </div>
      </div>
      <div class="list">
        <div class="list-item">
          <div class="goods-img">
            <img src="@/assets/product.jpg" alt="">
          </div>
          <div class="goods-content text-ellipsis-2">
            小米手机 Xiaomi14pro  12GB+256GB 徕卡Summilux可变光圈镜头 第三代骁龙®8移动平台 白色 5G手机
          </div>
          <div class="goods-trade">
            <p>¥ 4299.00</p>
            <p>x 1</p>
          </div>
        </div>
        <div class="list-item">
          <div class="goods-img">
            <img src="@/assets/product.jpg" alt="">
          </div>
          <div class="goods-content text-ellipsis-2">
            小米手机 Xiaomi14pro  12GB+256GB 徕卡Summilux可变光圈镜头 第三代骁龙®8移动平台 白色 5G手机
          </div>
          <div class="goods-trade">
            <p>¥ 4299.00</p>
            <p>x 1</p>
          </div>
        </div>
        <div class="list-item">
          <div class="goods-img">
            <img src="@/assets/product.jpg" alt="">
          </div>
          <div class="goods-content text-ellipsis-2">
            小米手机 Xiaomi14pro  12GB+256GB 徕卡Summilux可变光圈镜头 第三代骁龙®8移动平台 白色 5G手机
          </div>
          <div class="goods-trade">
            <p>¥ 4299.00</p>
            <p>x 3</p>
          </div>
        </div>
      </div>
      <div class="total">
        共5件商品，总金额 ¥21495.00
      </div>
      <div class="actions">
        <span v-if="false">立刻付款</span>
        <span v-if="true">申请取消</span>
        <span v-if="false">确认收货</span>
        <span v-if="false">评价</span>
      </div>
    </div>
  </template>

<script>
export default {

}
</script>

  <style lang="less" scoped>
  .order-list-item {
    margin: 10px auto;
    width: 94%;
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 0 0.5px 2px 0 rgba(0,0,0,.05);
    border-radius: 8px;
    color: #333;
    font-size: 13px;

    .tit {
      height: 24px;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .status {
        color: #fa2209;
      }
    }

    .list-item {
      display: flex;
      .goods-img {
        width: 90px;
        height: 90px;
        margin: 0px 10px 10px 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .goods-content {
        flex: 2;
        line-height: 18px;
        max-height: 36px;
        margin-top: 8px;
      }
      .goods-trade {
        flex: 1;
        line-height: 18px;
        text-align: right;
        color: #b39999;
        margin-top: 8px;
      }
    }

    .total {
      text-align: right;
    }
    .actions {
      text-align: right;
      span {
        display: inline-block;
        height: 28px;
        line-height: 28px;
        color: #383838;
        border: 0.5px solid #a8a8a8;
        font-size: 14px;
        padding: 0 15px;
        border-radius: 5px;
        margin: 10px 0;
      }
    }
  }
  </style>
