var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay"},[_c('van-nav-bar',{attrs:{"fixed":"","title":"订单结算台","left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"address"},[_c('div',{staticClass:"left-icon"},[_c('van-icon',{attrs:{"name":"logistics"}})],1),(true)?_c('div',{staticClass:"info"},[_vm._m(0),_c('div',{staticClass:"info-address"},[_vm._v(" 江苏省 无锡市 南长街 110号 504 ")])]):_c('div',{staticClass:"info"},[_vm._v(" 请选择配送地址 ")]),_c('div',{staticClass:"right-icon"},[_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('div',{staticClass:"pay-list"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"pay-detail"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"pay-cell"},[_c('span',[_vm._v("配送费用：")]),(false)?_c('span',[_vm._v("请先选择配送地址")]):_c('span',{staticClass:"red"},[_vm._v("+￥0.00")])])]),_c('div',{staticClass:"pay-way"},[_c('span',{staticClass:"tit"},[_vm._v("支付方式")]),_c('div',{staticClass:"pay-cell"},[_c('span',[_c('van-icon',{attrs:{"name":"balance-o"}}),_vm._v("余额支付（可用 ¥ 1456700.57 元）")],1),_c('span',{staticClass:"red"},[_c('van-icon',{attrs:{"name":"passed"}})],1)])]),_vm._m(5)]),_c('div',{staticClass:"footer-fixed"},[_vm._m(6),_c('div',{staticClass:"tipsbtn",on:{"click":function($event){return _vm.$router.push('/myorder')}}},[_vm._v("提交订单")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-content"},[_c('span',{staticClass:"name"},[_vm._v("小枫")]),_c('span',{staticClass:"mobile"},[_vm._v("15751776629")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"goods-item"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/product.jpg"),"alt":""}})]),_c('div',{staticClass:"right"},[_c('p',{staticClass:"tit text-ellipsis-2"},[_vm._v(" 小米手机 Xiaomi14pro 12GB+256GB 徕卡Summilux可变光圈镜头 第三代骁龙®8移动平台 白色 ")]),_c('p',{staticClass:"info"},[_c('span',{staticClass:"count"},[_vm._v("x5")]),_c('span',{staticClass:"price"},[_vm._v("¥4299")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flow-num-box"},[_c('span',[_vm._v("共 5 件商品，合计：")]),_c('span',{staticClass:"money"},[_vm._v("￥21495")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-cell"},[_c('span',[_vm._v("订单总金额：")]),_c('span',{staticClass:"red"},[_vm._v("￥21495.00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-cell"},[_c('span',[_vm._v("优惠券：")]),_c('span',[_vm._v("无优惠券可用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"buytips"},[_c('textarea',{attrs:{"placeholder":"选填：买家留言（50字内）","name":"","id":"","cols":"30","rows":"10"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_vm._v("实付款："),_c('span',[_vm._v("￥21495")])])
}]

export { render, staticRenderFns }